:root {
  --tblr-font-sans-serif: 'DM Sans';
  --tblr-btn-border-width: 1.5px !important;
}
#root {
  /* min-height: 100%; */
}
.page,
.page-wrapper {
  /* min-height: 100%; */
  /* height: 100%; */
}
.page-body {
  /* min-height: calc(100vh - 486px); */
}
.animate-fade {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
header {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15) !important;
}
.search-bar {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.animate-fade.in {
  opacity: 1;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}
.top-backdrop {
  z-index: 1055;
}
.pac-container {
  z-index: 9999;
}

.navbar .navbar-nav {
  min-height: 2rem;
}
.text-opacity-80 {
  opacity: 0.8;
}
a.text-opacity-80:hover {
  opacity: 1;
}
.section-padding-1 {
  padding: 4rem 0 4rem 0;
}
.section-title {
  font-size: 2.75rem;
  font-weight: 700;
  line-height: 2.8rem;
}
.bg-building.card-cover::before {
  z-index: -1;
}
.bg-building {
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('../images/background/bg-search.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  z-index: 1;
}
.bg-building.card-cover:before {
  background: rgba(0, 0, 0, 0.5);
}
.search-section-h {
  min-height: 440px;
}
.search-section-h h1 {
  font-size: 38px;
  line-height: 48px;
}
.search-card {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}
.form-control.bg-transparent::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.border-1_half {
  border-width: 1.5px;
}
.transparent-select [class*='-singleValue'] {
  color: #ffffff;
}
input.bg-transparent.form-control:focus {
  border-color: rgba(255, 255, 255, 0.5) !important;
  box-shadow:
    0 0 transparent,
    0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important;
}
/* .transparent-select [class*="-menu"] {
    background-color: rgba(255, 255, 255, 0.3);
    border:1px solid;
    border-color: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
  } */
.bg-home-icon {
  position: relative;
}
.bg-home-icon:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images/background/bg-home.svg');
  background-repeat: no-repeat;
  background-size: 200px;
  background-position: left bottom;
  background-position-y: calc(100% - 2rem);
  z-index: -1;
}
.bg-home-icon.right:before {
  transform: scaleX(-1);
  background-color: #fff;
}
.tag {
  padding: 0.1875rem 0.625rem;
  font-weight: 500;
  border-radius: 0.875rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 1rem;
  left: 1rem;
}
.tag-fav {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-weight: 500;
}
.bg-lease-lt {
  /* background: rgba(27, 97, 181, 0.05); */
  background: #f0f5fb;
}
.sponsor-section {
  max-width: 70rem;
}
.sponsor-section h3,
.property-type h3 {
  color: #fff;
  text-align: center;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem; /* 133.333% */
}

.testimonial-comment {
  height: 10rem;
  overflow-y: auto;
}
.carousel-container {
  margin-right: calc(-0.5 * var(--tblr-gutter-x));
  margin-left: calc(-0.5 * var(--tblr-gutter-x));
}
.carousel-padding-bottom {
  padding-bottom: 4rem;
}
.carousel-custom-btn-group {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.mb-1-minus {
  margin-bottom: -3px;
}

.form-selectgroup-input:checked + .form-selectgroup-label.rc-input {
  z-index: 0;
  color: var(--tblr-white);
  background: rgba(var(--tblr-primary-rgb), 1);
  border-color: var(--tblr-primary);
}
.form-selectgroup-input:disabled + .form-selectgroup-label.rc-input {
  cursor: not-allowed;
}
.form-selectgroup-input:focus + .form-selectgroup-label.rc-input {
  z-index: 2;
  /* color: var(--tblr-white); */
  border-color: var(--tblr-primary);
  box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}
.border-dashed {
  border-style: dashed !important;
}
.upload-photos-label {
  width: 100%;
  padding-top: 55px;
  padding-bottom: 56px;
}
.upload-photos-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* min-height: 310px; */
}
.upload-photos {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.upload-photos-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.form-selectgroup-input:checked + .form-selectgroup-label.rc {
  background: rgba(var(--tblr-primary-rgb), 1);
  color: var(--tblr-light);
}
.w-150px {
  width: 150px;
}
.dropdown-arrow-none .dropdown-toggle::after {
  display: none;
}
.dd-action {
  min-width: 5rem !important;
}
.dd-action .dropdown-item {
  min-width: 5rem !important;
}
.dd-menu-profile {
  min-width: 220px;
  padding: 0.5rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  border: none;
  margin-top: 22px !important;
}
.dd-menu-profile .dropdown-item:hover,
.dd-menu-profile .dropdown-item:focus {
  color: var(--tblr-dropdown-link-active-color);
  background-color: transparent;
}
.dd-menu-profile .dropdown-item.active,
.dd-menu-profile .dropdown-item:active {
  background-color: transparent;
}
/* Rc calendar*/
.rc-calendar {
  width: 100%;
  border-color: var(--tblr-border-color);
  font-family: var(--tblr-font-sans-serif);
  z-index: 0;
}
.rc-calendar .react-datepicker__header {
  margin: 0 5px;
  padding: 15px 0;
  background-color: var(--tblr-bg-light);
  border-bottom: 0;
}
.rc-calendar .react-datepicker__navigation {
  top: 20px;
}
.rc-calendar .react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
}
.rc-calendar .react-datepicker__day-name {
  text-transform: uppercase;
  color: var(--tblr-muted);
  font-weight: 600;
}
.rc-calendar .react-datepicker__current-month {
  margin: 8px 0;
}
.rc-calendar .react-datepicker__month-container {
  width: 100%;
}
.rc-calendar .react-datepicker__day-name,
.rc-calendar .react-datepicker__day,
.rc-calendar .react-datepicker__time-name {
  width: 13%;
}
.bg-carousel {
  --tblr-bg-opacity: 0.5;
  background-color: rgba(
    var(--tblr-gray-900-rgb),
    var(--tblr-bg-opacity)
  ) !important;
}
.offer-list-group .list-group-item:hover,
.offer-list-group .list-group-item:focus,
.offer-list-group .list-group-item:active {
  background-color: transparent;
}

.card-title {
  font-size: 1.125rem;
}
.unit-features .accordion-button {
  padding: 3px 10px;
}
.fancybox__container {
  z-index: 1100 !important;
}
.slider-img {
  height: 470px;
  object-fit: fill;
  object-position: center center;
}
.badge-unit {
  bottom: unset !important;
  box-shadow: none !important;
  font-size: 0.75rem !important;
}
.proximitty-frame {
  width: 100%;
  height: 500px;
  border: none;
}
.type-dd .dropdown-menu {
  width: 400px;
}
.type-dd-btn {
  border: var(--tblr-border-width) solid var(--tblr-border-color);
  background: transparent;
}
.fill-white {
  fill: var(--tblr-white);
}
.type-dd-seperator {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 4px;
  align-self: stretch;
}
.type-dd-item {
  border: 1px solid var(--tblr-border-color);
  margin: 0 0.8rem 0.8rem 0;
  display: inline-block;
  width: auto;
  min-width: 1px !important;
  border-radius: var(--tblr-border-radius-pill);
  font-weight: 500;
}
.form-selectgroup-label.rc-input-time {
  border-color: var(--tblr-primary);
  color: var(--tblr-primary);
}
.w-100px {
  width: 100px;
}
.accordion-header.fw-semibold .accordion-button {
  font-weight: 600 !important;
}
.sticky-bar {
  position: sticky;
  top: 90px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1019;
}
.sticky-bar .nav-item .nav-link {
  color: var(--tblr-muted);
}
.sticky-bar .nav-item.active .nav-link {
  color: #000;
}

.property-title {
  font-size: 1.875rem;
}
.bg-subtype {
  background-color: rgba(27, 97, 181, 0.05) !important;
}
.fixed-width-container {
  min-width: 900px;
  max-width: 900px !important;
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
}
.fixed-width-body {
  width: 900px; /* Adjust the width as per your requirement */
  margin: auto;
}
.truncate-two-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines to show */
  line-height: 1.2; /* Line height of the text */
}
.nav-header-link.active {
  color: var(--tblr-primary) !important;
}
.rc-title-md {
  font-size: 2.25rem;
  line-height: 1.1428571429;
}
.rc-title-lg {
  font-size: 3rem;
  line-height: 1.4285714286;
}
.footer .contact::before {
  position: absolute;
  left: -40px;
  top: -48px;
  height: 131%;
  width: 500%;
  background: rgba(0, 66, 144, 1);
  content: '';
  z-index: -1;
}
.navbar-brand-image {
  height: 3rem;
}
/*pricing*/
.title-xl {
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 0;
}
.slider-container {
  padding: 20px;
}

.rc-slider-track {
  background-color: white !important;
  height: 4px !important;
}

.rc-slider-handle {
  border-color: white !important;
  height: 14px !important;
  width: 14px !important;
  margin-left: -5px !important;
  margin-top: -5px !important;
  background-color: white !important;
  border-radius: 2px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: 1 !important;
}
.rc-slider-handle {
  box-shadow: none !important;
}
.rc-slider-rail {
  background-color: rgba(255, 255, 255, 0.2) !important;
  height: 4px !important;
}

.rc-slider-handle::before {
  content: '';
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 10px;
  background-color: var(--tblr-primary);
  border-radius: 1px;
}

.rc-slider-handle::after {
  content: '';
  position: absolute;
  left: 33.3333%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 10px;
  background-color: var(--tblr-primary);
  border-radius: 1px;
  margin-left: 4px;
}
.add-on .form-check-input {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
}
.add-on .form-check-input:checked {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.button-count {
  position: absolute !important;
  right: -10px;
  top: -10px !important;
}
.dd-country {
  width: 110px;
}
@media only screen and (min-device-width: 768px) {
  .dropdown-menu {
    font-size: 16px !important;
  }
}
@media only screen and (max-device-width: 768px) {
  .navbar-brand-image {
    height: 2.5rem;
  }
}
